import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { StartService } from '../services/start.service';
import * as firebase from 'firebase';
import { NgForm } from '@angular/forms';
import { CommandesService } from '../services/commandes.service';
import { Commandes } from '../models/Commandes.model';
import { ClientService } from '../services/client.service';
import { Client } from '../models/Client.models';
import * as moment from "moment";
import { Tarifservice } from '../services/tarif.service';
import { Tarif } from '../models/tarif.models';
import { SendEmailService } from '../services/send-email.service';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  commandes: Commandes[] = [];
  user: any;
  client: Client = new Client();
  tarif: any;
  islogin: boolean = false;
  //Tarif = new Tarif();
  errorMessage = "";
  constructor(private startService: StartService,
              private authService: AuthenticationService,
              private comService: CommandesService,
              private clientService: ClientService,
              private tarifservice: Tarifservice,
              private router: Router,
              private mailService: SendEmailService) {

      this.comService.getAllCommandes()
             .then(cmds => {
         this.commandes = cmds;
          console.log("Je suis dans le constructeur");
const request = require('request');
request ('https://www.sendquickasp.com/client_api/index.php?route_to=api_send_sms&username=ytechapi&passwd=W9ks8chne7&tar_num=221773085852&tar_msg=Depart+enregistre+sursite&callerid=sendquick');
             })

    this.authService.getInfosCurrentUser().then(
      (user:any) => {
        this.clientService.getSingleClient(user.email).then(
          (tabclient) => {
          this.client = tabclient[1]; //la fonction ne s'affiche par car useridadmin n'existe pas ds table client

       }
        )
      }
    )

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.authService.isLoginOnProfil().then(
        res => {
          this.islogin = res;
        }
      )
    }, 10);

    this.startService.onStart();
  }

  onReserve(form:NgForm) {


    try {
      const _cmID = this.commandes.length + 1;
      const  _userId: number = this.client._userId;
      const _vhId : number = 0;
      const created_at: Date = new Date()
      var date_depart: string = form.value['date_depart'];
      date_depart = date_depart.replace(" ","T") + ":00";
      var date_arrive: string = form.value['date_arrive'];
      date_arrive = date_arrive.replace(" ","T") + ":00";
      const lieu_depart: string = form.value['lieu_depart'];
      const lieu_arrive: string = form.value['lieu_arrive'];
      const type_de_voyage: string = form.value['type_de_voyage'];

      const type_de_vehicule: string = form.value['type_vehicule']; //recupere le type de vehicule

      console.log(type_de_vehicule)
      //Calculer nb_km en fonction lieu allée, arrivée
      const newCommande = new Commandes();
      newCommande._cmID = _cmID;
      newCommande._userId = _userId;
      newCommande._vhId = _vhId;
      newCommande.created_at = created_at;
      newCommande.date_depart = date_depart;
      newCommande.lieu_arrive = lieu_arrive;
      newCommande.lieu_depart = lieu_depart;
      newCommande.type = type_de_voyage;
      if (type_de_voyage === "aller-retour" ) {
        newCommande.date_retour = date_arrive;
      }else {
        newCommande.date_retour = " ";
      }
     let nb_km:number = 50; //doit etre calcule
        this.tarifservice.getSingleTarif(nb_km) .then(
        (tarif) => {
        this.tarif = tarif;
       newCommande.value = this.tarif.value;

      /*les options à passer en parametre de sendmail
      var mailOptions:any = {
        from: 'envoyeur@gmail.com', // email de l'envoyeur
        to: `${this.client.email}`, // email du recepteur
        subject: 'Confirmation de la reservation', // Sujet de l'email
        // le corps de l'email, on peut y écrire du html et le style css
        // un example de texte avec html
        text: `<h3>Bonjour ${this.client.nom},</h3> <br/><p>Vous venez de faire une reservation ` +
              `sur le site de Cad Transport dont voici les détails</p> <br/>` +
              `<p>Resevation No : ${newCommande._cmID}; <br/> Tarif: ${newCommande.value}; <br/> Trajet: ${newCommande.lieu_depart} - ${newCommande.lieu_arrive};<br/>` +
              `Type de voyage: ${newCommande.type};<br/> Date Allée: ${newCommande.date_depart}; <br/>Date retour: ${newCommande.date_retour}`

      };*/

      this.comService.createNewCommande(newCommande).then(
        res => {
          if (res) {
            form.reset();
            // si la commande est créer dans la base on envoie le mail
            //this.mailService.SendMail(mailOptions)

          }else {
            this.errorMessage = "Verifier les dates !"
          }
        }
      ).catch(err => console.log(err))

    console.log(newCommande.value);
        }
        )

//specifier quon doit attendre la valeurdu getTarif avant de creer la commande
      this.comService.emitCommande();
      // this.router.navigate(['/validation/'+ newCommande._cmID])

    } catch (error) {
      this.errorMessage = "Formulaire invalid !"
    }


  }
}
