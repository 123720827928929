import { Injectable, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import * as firebase from 'firebase';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthenticationService } from './authentication.service';
import { Vehicule } from '../models/Vehicule.model';
import { CommandesService } from '../services/commandes.service';


@Injectable({
 providedIn: 'root'
})

export class vehiculeServices {
private vehiculesindispo: any[] = [];
private  vehicules: any[] = [];
private  commandes: any[] = [];
private vehiculechoisi: number = 0;

constructor(private db: AngularFireDatabase,
            private comService: CommandesService){

}
getVehiculesFromServer() {
    return new Promise<any>((resolve, reject)=> {
      this.db.list('vehicule').valueChanges()
              .subscribe(vehicules => {
              resolve(vehicules);
      })
    })
  }
//Recuperer vehicule dispo selon date et type
getVehiculeDispo(Type:string, dateallee:string, dateretour:string) {
this.getVehiculeIndispo(dateallee,dateretour);
this.getVehiculesFromServer ()
.then(vehicules =>{
this.vehicules = vehicules;
this.vehicules.forEach(vh => {
  if (vh.type == Type) {
  for(var i= 0; i < this.vehiculesindispo.length; i++){
    if (vh.vhId != this.vehiculesindispo[i]){
      this.vehiculechoisi = vh.vhId
      }
  }
}
})
return this.vehiculechoisi;
})
}

//recuperer les vehicules sollicitées dans les commandes actives
getVehiculeIndispo( dateallee:string, dateretour:string){//get vehicules de commandes en cours
this.comService.getCmdActiveByDate(dateallee, dateretour)
    .then(commandes => {
        this.commandes.forEach(cmd => {
  for(var i= 0; i < this.vehiculesindispo.length; i++){
        this.vehiculesindispo[i] = cmd.vhId;
  }
    })
})
}
}

