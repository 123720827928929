import { Injectable } from '@angular/core';
declare var $:any;
declare var jQuery:any;

@Injectable({
  providedIn: 'root'
})
export class StartService {

  constructor() { }

  collapse() {
    var accItem = document.getElementsByClassName('accordionItem');
    var accHD = document.getElementsByClassName('accordionItemHeading');
    
    var toggleItem = (e:any) => {
        var itemClass = e.target.parentNode.className;
        for (i = 0; i < accItem.length; i++) {
            accItem[i].className = 'accordionItem close';
        }
        if (itemClass == 'accordionItem close') {
          e.target.parentNode.className = 'accordionItem open';
        }
    }
    for (var i = 0; i < accHD.length; i++) {
      accHD[i].addEventListener('click', toggleItem, false);
    }
  }

  onStart() {

    //formulaire de reservation
    $('.chips').chips({
      data: [{
        tag: 'Taxi',
      },{
        tag: 'Utilitaire'
      }
    ]
    });
    function onDate() {
      jQuery.datetimepicker.setLocale('fr');
      $('.target-form input.autocomplete').autocomplete({
          data: {
              "Dakar": null,
              "Thies": null,
              "Ville 3": null,
              "Ville 4": null
          }
      });
    var newdate = new Date();
      $('#datepicker1').datetimepicker({
          timepicker: true,
          datepicker: true,
          format:'Y-m-d H:i',
          minDate: newdate.getFullYear() + "-" + newdate.getMonth()+1 +"-" + newdate.getDate(),
          onShow: function() {
              this.setOptions({
                  maxDate: $('#datepicker2').val() ? $('#datepicker2').val() : false
              })
          }
      });
    
      $('#datepicker2').datetimepicker({
          timepicker: true,
          datepicker: true,
          format:'Y-m-d H:i',
          onShow: function() {
              this.setOptions({
                  minDate: $('#datepicker1').val() ? $('#datepicker1').val() : newdate.getFullYear() + "-" + newdate.getMonth()+1 +"-" + newdate.getDate(),
              })
          }
      });
    }
    onDate();
   
    function custom() {
      (function ($) {

        // "use strict"; 
      
        /*=================== PRELOADER ===================*/
        $(window).on('load',function() { 
            $(".preloading").fadeOut("slow"); 
        });
      
        /*=================== SIDENAV  ===================*/
        
        $('.sidenav').sidenav({
            menuWidth: 250, 
            edge: 'left',
            closeOnClick: true, 
            draggable: true, 
            onOpenEnd: function(el:any) {
            }, 
            onCloseEnd: function(el:any) {
            }, 
          }
        );
        $(document).ready(function(){
          $('.collapsible').collapsible();
        });

        /*=================== CAROUSEL SLIDER  ===================*/
      
        // $('.carousel.carousel-slider').carousel({fullWidth: true},setTimeout(autoplay, 4500));
        //   function autoplay() {
        //     $('.carousel').carousel('next');
        //     setTimeout(autoplay, 4500);
        //      }
      
        /*=================== GALLERY FILTERING FUCTION  ===================*/
        // $(".filter-button").on("click", function() {
        //     var value = $(".filter-button").attr('data-filter');
            
        //     if(value == "gallery-no-filter")
        //     {
        //         $('.gallery-img-box').removeClass("gallery-hidden");
        //     }
        //     else
        //     { 
        //         $(".gallery-img-box").not('.'+value).addClass("gallery-hidden");   
        //         $('.gallery-img-box').filter('.'+value).removeClass("gallery-hidden");
                
        //     }
        // });
      
        // $('.filter-gallery .filter-button').on("click", function() {
        //     $('.filter-gallery').find('.filter-button.active').removeClass('active');  
        //     $('.filter-gallery .filter-button').addClass('active');
        // });
      
        /*=================== MAGNIFICPOPUP GALLERY  ===================*/
        $(".gallery-list").magnificPopup({
                type: "image",
                removalDelay: 300 
            });
      
        // ======================= PROMO  ======================= // 
        //  if($('#promo-item').length > 0){
        //     $("#promo-item").owlCarousel({
        //       dots: false,
        //       loop: true,
        //       autoplay: true,
        //       slideSpeed : 2000,
        //       margin: 0,
        //       responsiveClass: true,
        //       nav: false, 
        //          navText: ["<i class=\"fa fa-angle-left\" aria-hidden=\"true\"></i>", "<i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>"], 
        //       responsive: {
        //         0: {
        //           items: 1,
        //           nav: false
        //         },
        //         480: {
        //           items: 1,
        //           nav: false
        //         },
        //         600: {
        //           items: 1,
        //          nav: false
        //         },
        //         1000: {
        //           items: 1,
        //           nav: false, 
        //           margin: 0
        //         }
        //       }
               
        //     });
        //   }
      
        // ======================= TESTIMONIAL  ======================= // 
        //  if($('#testimonial-item').length > 0){
        //     $("#testimonial-item").owlCarousel({
        //       dots: true,
        //       loop: true,
        //       autoplay: true,
        //       slideSpeed : 2000,
        //       margin: 0,
        //       responsiveClass: true,
        //       nav: false, 
        //          navText: ["<i class=\"fa fa-angle-left\" aria-hidden=\"true\"></i>", "<i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>"], 
        //       responsive: {
        //         0: {
        //           items: 1,
        //           nav: false
        //         },
        //         480: {
        //           items: 1,
        //           nav: false
        //         },
        //         600: {
        //           items: 1,
        //          nav: false
        //         },
        //         1000: {
        //           items: 1,
        //           nav: false, 
        //           margin: 0
        //         }
        //       }
               
        //     });
        //   }
      
        /*=================== FOOTER ===================*/ 
        // $('#page-content').css('min-height',$(document).height() - ($('#header').height() + $('.section.top-header-menu').height() ) - $('#footer').height() );
      
      })(jQuery);
    }

    custom();

  }
     
}
