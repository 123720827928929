import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import * as firebase from "firebase";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  userId: any;

  constructor(private db: AngularFireDatabase,
              private asAuth: AngularFireAuth,
              private router: Router) {
    
  }
  
  addNewUser(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        this.asAuth.createUserWithEmailAndPassword(email, password).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  getInfosCurrentUser() {
    return new Promise((resolve, reject) => {
      this.asAuth.authState.subscribe(user => {
        if(user) {
          this.userId = user;
        }else {
          this.userId = false;
        }
        resolve(this.userId)
      })
    })
  }

  isLoginOnProfil() {
    return new Promise<boolean>((resolve, reject) => {
      this.getInfosCurrentUser()
      .then(res => {
        const user:any = res;
        if (res && this.router.url.startsWith("/profil")) {
          resolve(true)
        }else {
          resolve(false)
        }
    })
   })
  }
 
  login(email: string, password: string) {
    return new Promise(
      (resolve, reject) => {
        this.asAuth.signInWithEmailAndPassword(email, password).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  logout() {
    this.asAuth.signOut();
  }



}
