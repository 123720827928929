import { ViewportScroller } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { ClientService } from '../services/client.service';
import { StartService } from '../services/start.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  islogin: boolean = false;
  client:any;
  constructor(private startService: StartService,
              private clientService: ClientService,
              private authService: AuthenticationService,
              private router: Router,
              private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
      
    this.startService.onStart();
      setInterval(() => {
        this.authService.isLoginOnProfil()
          .then(res => {
            this.islogin = res;
          })
      },1000)
      

    
    this.authService.getInfosCurrentUser()
      .then(res => {
        const user:any = res;
        this.clientService.getSingleClient(user.email)
          .then(tabclient => {
            var id = tabclient[0]
            this.client = tabclient[1]
            console.log(this.client);
          })
      })
    
  }
  onClickScroll(elemntId: string) {
    this.viewportScroller.scrollToAnchor(elemntId);
  }

}
