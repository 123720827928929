import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Client } from '../models/Client.models';
import { Commandes } from '../models/Commandes.model';
import { ClientService } from './client.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthenticationService } from './authentication.service';
import { ToastrService } from 'ngx-toastr';
import { SendEmailService } from './send-email.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CommandesService {
  private commandes : any[] = [

  ]

  public commandeSub = new Subject<Commandes[]>();
  user: any;
  email: string= "";
  myCommandes : Commandes[] = []
  client: Client = new Client();

  constructor(private clientService: ClientService,
              private db: AngularFireDatabase,
              private authService: AuthenticationService,
              private toastr: ToastrService,
              private emailService: SendEmailService) {

    this.getAllCommandes()
      .then(com => {
        this.commandes = com;
        console.log(this.commandes)
        this.emitCommande()
    })

  }

  emitCommande() {
    this.commandeSub.next(this.commandes);
  }

  saveCommande() {
   this.db.object('/commandes').update(this.commandes);
  }

  /**
   * @param newCom : Nouvelle commande à enregistrés
   */
  createNewCommande(newCom: Commandes) {
    return new Promise((resolve, reject) => {
      this.ValidCommand(newCom).then(res => {
        const message = res[0];
        const envoi = res[1];
  
        // si la commande est validée envoi==true
        if (envoi === true) {
          this.commandes.push(newCom);
  
          this.saveCommande();
          this.emitCommande();
          this.getCmdByUser().then(
            (tabcom) => {
              var mycom = tabcom[2];
              var client = tabcom[1]
              var id = tabcom[0]
              mycom.push(newCom);
              var value = {
                ...client,
                commandes: mycom
              }

              this.clientService.updateClient(id,value);
              this.clientService.saveClient();
              this.clientService.emitClient();

              /*
              // sending email
              var options = {
                message: " ", // message du mail
                to: "${}", // email du destinateur
                subject: "", // sujet d'email
              }
              */
            

              this.toastr.success(message, "Confirmation")
              resolve(true)
            })
            }else {
              this.toastr.error(message)
            }
  
          }).catch(err => reject(err))
    })
    
  }

  /**
   * @returns [id, client, commandes]: renvoi un tableau contenant key de client, obj client conneté et toutes ses commandes
   */
  getCmdByUser(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.authService.getInfosCurrentUser()
        .then(res => {
          this.user = res;
          this.email = this.user.email;
          this.clientService.getSingleClient(this.email).then(tab => {
            this.client = tab[1];
            var id = tab[0]
            this.myCommandes = this.client.commandes ? this.client.commandes : [];
            resolve([id,this.client,this.myCommandes])
     })},(error) => {
       reject(error)
     })
    })  
  }

  /**
   * @param active: booléan, true si active et false si inactive
   * @returns un tableau contenant les commandes actives du client connecté
   */
  getCmdActiveByUser(active=true) {
    var comActibeByUser;
    var cominactivebyUser;
    return new Promise<any[]>((resolve, reject) => {
      this.getCmdByUser()
        .then(res => {
          var id = res[0]
          var client= res[1]
          var mycom = res[2]
          if (active===true) {
            comActibeByUser = mycom.filter((el:any) => el.active === true)
            resolve(comActibeByUser);
          }else {
            cominactivebyUser = mycom.filter((el:any) => el.active === false)
            resolve(cominactivebyUser);
          }
          
          
        }).catch(err => reject(err))
    })
  }
  
 /**
  * @returns toutes les commandes de la base
  */
  getAllCommandes() {
    return new Promise<any[]>((resolve, reject)=> {
      this.db.list('commandes').valueChanges()
              .subscribe(commandes => {
                resolve(commandes)
      })
    })
  }

  /**
   * @param active: boolean
   * @returns toutes les commandes actives dans la base si active=true
   */
  getCommandeEncours(active=true) {
    return new Promise<any[]>((resolve, reject) => {
      var comactives: any[];
      var comnonactives: any[];
      this.getAllCommandes().then(
        res=> {
          if(active===true) {
            comactives = res.filter((el:any) => el.active===true)
            resolve(comactives)
          }else {
            comnonactives = res.filter(el => el.active === false)
            resolve(comnonactives)
          }
        }
      ).catch(err => reject(err))
    })

  }

  /**
   * 
   * @param date_depart : date de requette en string ex : "2020-12-28T18:00:00" 
   * @param date_retour
   */
  getCmdActiveByDate(date_depart: string, date_retour: string) {
    var comFilterByDate:any;
    return new Promise<any[]>((resolve, reject) => {
      this.getCommandeEncours(true)
        .then(res => {
          comFilterByDate = res.filter(el => {
            return (Date.parse(el.date_depart)===Date.parse(date_depart))
          })
          comFilterByDate = comFilterByDate + res.filter(el => {
            return (Date.parse(el.date_retour)===Date.parse(date_retour))
          })

          resolve(comFilterByDate);
        }).catch(err => reject(err))
      })
  }

  /**
   * 
   * @param id : key de commandes à supprimer dans tables commandes
   * @returns obj commande supprimé
   */
  deleteOneCommandGlobal(id: number) {
    return this.db.object('/commandes/'+id).remove()
  }

  updateCommand(id: number, value: any) {
    return this.db.object('/commandes/'+ id).update(value);
  }
  /**
   * 
   * @param idcom : key de commandes de client 
   * @returns obj commande supprimé
   */
  failedCommande(idcom:number) {
    this.getCmdByUser()
    .then(tabclient => {
      var idclient = tabclient[0];
      var client = tabclient[1];
      var com = tabclient[2];
      var index: number = this.commandes.indexOf(com[idcom])
      this.deleteOneCommandGlobal(index);
      return this.db.object('/clients/'+ idclient + '/commandes/' + idcom).remove();
    })
  }

  /**
   * Desactive les commndes d'un client deja terminées
   * @param idclient : number
   * @param client : client connecté
   * @param mycom : les commandes du client conneté
   */
  disabledCommand(idclient:number, client:any, mycom:any[]):any {
        mycom.forEach((el:any)=> {
          if (el.active == true && (Date.parse(el.date_depart) < Date.now())) {
            el.active = false
          }else if(el.active == true && (Date.parse(el.date_arrive) < Date.now())) {
            el.active = false
          }
        })
        var allcom: any;
        this.getAllCommandes().then(
          res => {
            allcom = res;
            allcom.forEach((el:any, index:number) => {
              if (el.active == true && (Date.parse(el.date_depart) < Date.now())) {
                el.active = false
              }else if(el.active == true && (Date.parse(el.date_arrive) < Date.now())) {
                el.active = false
              }
              this.updateCommand(index,el);
              this.emitCommande();
            })
          }
        )
        
        var value = {
          ...client,
          commandes: mycom
        }
        this.clientService.updateClient(idclient,value);
        this.clientService.saveClient();
        this.clientService.emitClient();
  }

  /**
   * 
   * @param date_entre : Date_String
   * @param date_retour :Date_String
   * @param global : True si le check conserne toute la table commandes, False si le check concerne les commandes d'un seul client.
   * @returns true: si la date coicide et false sinon
   */
  ckeckDate(date_entre:string, date_retour:string,global=true) {

    if (global===true) {
      var comactive: any[];
      return new Promise((resolve, reject) => {
          this.getCommandeEncours(true).then( res =>{
            comactive = res;
            var state = false;
          for (var i=0; i<comactive.length;i++) {
            if (Date.parse(comactive[i].date_depart) == Date.parse(date_entre)) {
              state = true 
              console.log("oui 1")
              break   
            }else if (Date.parse(comactive[i].date_depart) == Date.parse(date_retour)) {
              state = true
              console.log("oui 2")
              break
            }else if (Date.parse(comactive[i].date_retour) == Date.parse(date_entre)) {
              state = true
              console.log("oui 3")
              break
            }else if (Date.parse(comactive[i].date_retour) == Date.parse(date_retour)) {
              state = true
              console.log("oui 4")
              break
            }
          }
          resolve(state);
        })
        })

    }else { // si le check concerne les commandes d'un seul client
      return new Promise((resolve, reject) => {
        this.getCmdActiveByUser(true)
        .then((tabclient)=> {
          var mycom: any[] = tabclient;
          var mycomEncours = mycom
          let state = false;
          for (var i=0; i<mycomEncours.length;i++) {
            if (Date.parse(mycomEncours[i].date_depart) == Date.parse(date_entre)) {
              state = true 
              break   
            }else if (Date.parse(mycomEncours[i].date_depart) == Date.parse(date_retour)) {
              state = true
              break
            }else if (Date.parse(mycomEncours[i].date_retour) == Date.parse(date_entre)) {
              state = true
              break
            }else if (Date.parse(mycomEncours[i].date_retour) == Date.parse(date_retour)) {
              state = true
              break
            }
          }
        resolve(state);
      }).catch(error => {
        reject(error)
      })
    })
    }
  }

  
  /**
   * validation de la nouvelle commande pour un client
   * @param newCom : nouvelle commande à validé
   * @returns [message, envoi]: message de succes ou d'echec et envoi pour email
   */
  ValidCommand(newCom: Commandes) {
    return new Promise<any[]>((resolve, reject) => {
      console.log(newCom.date_depart)
      this.ckeckDate(newCom.date_depart.toString(), newCom.date_retour.toString(), false)
        .then(res =>{
          console.log(res)
          if(res === true) { 
            resolve(["Commande refusée", false]);
          }else {
            resolve(["Commande reussie", true]);
          }
        }).catch(err => reject(err))
    })
    
  }

}
