

<!-- CONTENT -->
<div id="page-content">
  <div class="section news">
    <div class="container">
      <div class="row row-title">
        <div class="col s12">
          <div class="section-title">
            <span class="theme-secondary-color">Nos</span> Services

          </div>
<img src="../../assets/img/parcours.jpg" alt="image-news">
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <div class="news-content">
          <!--<img src="" alt="image-news"> -->
            <div class="news-detail">
              <h5 class="news-title"><a href="">Service de transport à l'aéroport</a></h5>
              <p>
Pour les transferts en taxi et en voiture privée avec chauffeur, votre chauffeur vous attendra dans les zones d’arrivées. Compte tenu du COVid, Il portera un équipement de protection tout au long du trajet et les véhicules sont nettoyés et désinfectés entre chaque voyage.               </p>
              <div class="readmore-news">
                <a class="readmore-btn">Reservez</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col s12">
          <div class="news-content">
           <!-- <img src="" alt="image-news">-->
            <div class="news-detail">
              <h5 class="news-title"><a href="">Véhicules utilitaires</a></h5>
              <p>
CAD, c'est une flotte de véhicules adaptés selon l'activité et disponibles partout au Sénégal :
Louez un véhicule pour cause de travaux, organisez un déménagement ou transporter des marchandise.

              <div class="readmore-news">
                <a class="readmore-btn">Reservez</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col s12">
          <div class="news-content">
          <!--  <img src="" alt="image-news"> -->
            <div class="news-detail">
              <h5 class="news-title"><a href="">Assistance Déménagement</a></h5>

              <p>
 Vous déménagez et vous vous demandez comment acheminer vos affaires ? Que ce soit à Dakar ou en région, nous évaluons les trajets et vous assistons avec un véhicule spacieux  et un service de chauffeur tout le long du déménagement.         </p>
              <div class="readmore-news">
                <a class="readmore-btn">Reservez</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col s12">
        <ul class="pagination">
          <li class="disabled">
            <a href="#!"><i class="material-icons">chevron_left</i></a>
          </li>
          <li class="active">
            <a href="#!">1</a>
         ><!-- </li>
          <li class="waves-effect">
            <a href="#!">2</a>
          </li>
          <li class="waves-effect">
            <a href="#!">3</a>
          </li>
          <li class="waves-effect">
            <a href="#!">4</a>
          </li>
          <li class="waves-effect">
            <a href="#!">5</a>
          </li>-->
          <li class="waves-effect">
            <a href="#!"><i class="material-icons">chevron_right</i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- END CONTENT -->
