
<div id="page-content">
    <div class="container">
      <nav>
        <div class="nav-wrapper">
          <ul id="nav-mobile" class="right hide-on-med-and-down"> 
            <a class="waves-effect waves-light btn" (click) ="onLogout()">Se deconnecter</a>
          </ul>
        </div>
      </nav>
      <div class="row">
        <div class="col s12">
          <div class="accordionWrapper">
            <div class="accordionItem open" >
                  <h4 class="accordionItemHeading">Commandes en cours <i class="fa fa-caret-down right"></i></h4>
                  <div class="accordionItemContent" id="encours">
                    <div *ngIf="commandesEnCours;else nonencours">
                      <ul *ngFor="let com of commandesEnCours; let i=index">
                        <li>Commande n<sup style="text-decoration:underline;">o</sup> : {{com._cmID}}</li>
                        <li *ngIf="com.active;else autreEtat">Etat : Active</li>
                        <ng-template #autreEtat>
                          <li>
                            Etat : inactive
                          </li>
                        </ng-template>
                        <li>Type de voyage: {{com.type}}</li>
                        <li>Date alléé : {{com.date_depart|date:"d/MMM/YYYY HH:mm"}}</li>
                        <li *ngIf="com.type =='aller-retour'">Date Retour : {{com.date_retour|date:"d/MMM/YYYY HH:mm"}}</li>
                        <hr>
                      </ul>
                  </div>
                  <ng-template #nonencours> <p>Pas de commandes en cours</p></ng-template>
                  </div>
                </div>
            
                <div class="accordionItem close" >
                  <h4 class="accordionItemHeading">Commandes Terminées<i class="fa fa-caret-down right"></i></h4>
                  <div class="accordionItemContent " id="termined">
                    <ul *ngFor="let com of commandTermine; let i=index">
                      <li>Commande n<sup style="text-decoration:underline;">o</sup> : {{com._cmID}}</li>
                      <li *ngIf="com.active;else autreEtat">Etat : Active</li>
                      <ng-template #autreEtat>
                        <li>
                          Etat : terminée
                        </li>
                      </ng-template>
                      <li>Type de voyage: {{com.type}}</li>
                      <li>Date alléé : {{com.date_depart|date:"d/MMM/YYYY HH:mm"}}</li>
                      <li *ngIf="com.type =='aller-retour'">Date Retour : {{com.date_retour|date:"d/MMM/YYYY HH:mm"}}</li>
                      <hr>
                    </ul>
                  </div>
                </div>
            
                <div class="accordionItem close" id="trajets">
                  <h4 class="accordionItemHeading">Mes Trajets <i class="fa fa-caret-down right"></i></h4>
                  <div class="accordionItemContent">
                    <p>Click an accordion item's heading to expand it. To collapse the item, click it again, or click another item heading.</p>
                  </div>
                </div>
                <div class="accordionItem close" id="factures">
                  
                  <h4 class="accordionItemHeading">Mes Factures <i class="fa fa-caret-down right"></i></h4>
                  <div class="accordionItemContent">
                    <p>Click an accordion item's heading to expand it. To collapse the item, click it again, or click another item heading.</p>
                  </div>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
    <!-- END CONTENT -->
