import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { map } from 'jquery';
import { AuthenticationService } from './services/authentication.service';
import { ClientService } from './services/client.service';
import { StartService } from './services/start.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'cadapp';
  islogin: boolean = false;
  client: any;
  constructor(private startService: StartService,
              private clientService: ClientService,
              private db: AngularFireDatabase,
              private router: Router,
              private authService: AuthenticationService) {
    
    // Your web app's Firebase configuration
    // var firebaseConfig = {
    //   apiKey: "AIzaSyAMHwHwlf-lD-LUOGnfui8I99pHb4NAIqo",
    //   authDomain: "cadapp-ba427.firebaseapp.com",
    //   projectId: "cadapp-ba427",
    //   storageBucket: "cadapp-ba427.appspot.com",
    //   messagingSenderId: "629018247314",
    //   appId: "1:629018247314:web:13b4602084ae5e31c6e64b"
    // };
    // Initialize Firebase
    // firebase.initializeApp(firebaseConfig);


  }

  ngOnInit() {
    this.startService.onStart();
    /*this.authService.getInfosCurrentUser()
      .then(res => {
        const user:any = res;
        if (res && this.router.url.startsWith('/profil')) {
          this.islogin = true;
        }
        this.clientService.getSingleClient(user.email)
          .then(tabclient => {
            var id = tabclient[0]
            this.client = tabclient[1]
            console.log(this.client);
          })
      })*/
  }

}
