import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../services/authentication.service';
import { ClientService } from '../services/client.service';
import { Client } from '../models/Client.models';
import { Commandes } from '../models/Commandes.model';
import { CommandesService } from '../services/commandes.service';
import { StartService } from '../services/start.service';
import { ViewportScroller } from '@angular/common';

declare var $:any;
@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {
  
  client: Client = new Client();
  myCommandes : Commandes[] = []
  commandesEnCours: any;
  commandTermine: any;
  user: any;
  email: string = "";
  islogin= false;

  constructor(public title: Title,
              private authservice: AuthenticationService,
              private clientService: ClientService,
              private commandeService: CommandesService,
              private authService: AuthenticationService,
              private startService: StartService,
              private viewportScroller: ViewportScroller) {
    this.title.setTitle("Cad Transport Profil")
      
   }

  ngOnInit(): void {

    setTimeout(() => {
      this.authService.isLoginOnProfil().then(
        res => {
          this.islogin = res;
        }
      )
    }, 10);

    this.authservice.getInfosCurrentUser()
        .then(res => {
          this.user = res;
          this.email = this.user.email;

          this.clientService.getSingleClient(this.email).then(tabclient => {
            this.client = tabclient[1];
            this.myCommandes = this.client.commandes;
            this.commandeService.disabledCommand(tabclient[0],this.client, this.myCommandes);
            this.clientService.emitClient();
          })
          
        })

    this.startService.collapse();

    this.commandeService.getCmdActiveByUser(true).then(
      res => {
        this.commandesEnCours = res.slice(1,res.length) // On ignore la commande 0 créée par defaut
      }
    )

   this.commandeService.getCmdActiveByUser(false).then(
     res => {
       this.commandTermine = res;
     }
   )

  }

  onLogout() {
    this.authservice.logout()
    console.log("deconnected !")
  }
  


}
