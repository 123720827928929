import { Injectable } from '@angular/core';
import { any } from 'bluebird';
import * as nodemailer from 'nodemailer';

declare var Email: any;


@Injectable({
  providedIn: 'root'
})
export class SendEmailService {

  constructor() {

   }
  
  SendMail(mailOptions:any) {
    return new Promise<any>((resolve, reject) => {
      Email.send({
        Host: 'smtp.gmail.com',
        Username: 'envoyeur@gmail.com', // email de l'envoyeur
        Password: 'mot-de-passe', // mot de passe de l'envoyeur
        To: mailOptions.to,
        From: mailOptions.from,
        Subject: mailOptions.subject,
        Body: mailOptions.text
      }).then((message:any) => {
      }).catch((err:any) => console.log(err))

  })
  }

}
