import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthCtrlService implements CanActivate{

  constructor(private router: Router,
              private asAuth: AngularFireAuth,
              private toastr: ToastrService) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      this.asAuth.onAuthStateChanged(
        (user) => {
          if(user) {
            resolve(true);
          } else {
            this.toastr.info("Connectez-vous pour continuer !","Login",{closeButton:true, easing:"ease-in-out"});
            this.router.navigate(['/login']);
            resolve(false);
          }
        }
      )
    })
  }
}
