
<!-- CONTENT -->
<div id="page-content">
    <div class="login-form">
      <div class="container">
        <div class="row">
          <div class="col s12">
            <div class="section-title">
              <span class="theme-secondary-color">Login</span> Account
            </div>
          </div>
        </div>
        <div class="row">
          <p class="col s12 offset-m3 offset-l4" *ngIf=" errorMessage" style="color: rgb(255, 123, 0);">{{ errorMessage }}</p>
        </div>
        <div class="row">
          <form class="col s12" (ngSubmit)="onLogin(f)" #f="ngForm">
            <div class="row">
              <div class="input-field col s12 m6 l4 offset-m3 offset-l4">
                <input name="email" id="email" type="text" class="validate" ngModel required>
                <label for="email">Email d'utilisateur </label>
              </div>
            </div>
            <div class="row">
              <div class="input-field col s12 m6 l4 offset-m3 offset-l4">
                <input name="password" id="password" type="password" class="validate" ngModel required>
                <label for="password">Mot de passe</label>
              </div>
            </div>
            <div class="row">
              <div class="input-field col s12 m6 l4 offset-m3 offset-l4 center">
                <input style="margin-right: 2rem;" class="waves-effect waves-red btn" value="Se connecter" type="submit">
                <a routerLink="/register" class="waves-effect waves-red btn" >S'inscrire</a>
            </div> 
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- END CONTENT -->