
<div id="homepage">
    <!-- BEGIN PRELOADING -->
    <!-- <div class="preloading">
      <div class="wrap-preload">
        <div class="cssload-loader"></div>
      </div>
    </div> -->
    <!-- END PRELOADING -->
    <!-- TOP MENU-->
  <div class="section top-header-menu">
    <div class="wrap-thm">
      <div class="container">
        <div class="row row-thm">
          <div class="col s12">
            <div class="thm-menu"> <a routerLink="profil">
              <i class="fa fa-user" aria-hidden="true"> </i> Mon Compte
         </a> </div>
            <div class="thm-menu"><a routerLink="">
              <i class="fa fa-globe" aria-hidden="true"></i> destinations
          </a>  </div>
            <div class="thm-menu"><a routerLink="services">
              <i class="fa fa-life-ring" aria-hidden="true"></i> Nos Services
            </a> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END TOP MENU-->

    <!-- MAIN SLIDER-->
    <div class="container " style="margin-top: 2rem;">
      <div class="row">
      <div class="travel-message img-responsive col s12">
        <div class="travel__tile" style="padding-top: 1em;"><span> Voyagez avec CAD partout au Sénégal en un clic!</span> </div>
        <div class="travel__forms row">
          <h1 class="travel__forms-title col s12">
            Choisissez votre itinéraire
          </h1>
          <!--Formulaire-->

            <div class="target-form s12">
              <p *ngIf="errorMessage">{{ errorMessage }}</p>
              <form action="" class="row"(ngSubmit)="onReserve(f)" #f="ngForm">
                <div class="col s12">
                  <div class="row">
                    <p class=" col s12 m6 center" >
                      <label >
                        <input class="with-gap blue" value="taxi" name="type_vehicule" style="color: white;" type="radio" checked ngModel>
                        <span style="color: white; font-weight:lighter;">Taxi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </label>
                    </p>
                    <p class="col s12 m6 center" >
                      <label >
                        <input class="with-gap" value="utilitaire" name="type_vehicule" style="color: white;" type="radio" ngModel>
                        <span style="color: white; font-weight:lighter;">Utilitaire</span>
                      </label>
                    </p>
                  </div>
                </div>
                <div class="col s12">
                  <div class="row">
                    <div class="input-field col s12 m6" >
                      <input name="lieu_depart" style="color: white;" type="text" id="autocomplete-input" class="autocomplete" placeholder="Lieu de départ" ngModel required>
                    </div>
                    <div class="input-field col s12 m6">
                      <input name="lieu_arrive" style="color: white;" type="text" id="autocomplete-input" class="autocomplete" placeholder="Lieu d'arrivée" ngModel required>
                    </div>
                  </div>
                </div>

                <div class="col s12">
                  <div class="row">
                    <div class="col s12 m6">
                      <input autocomplete="off" name="date_depart" style="color: #948f8f;" type="text" id="datepicker1" class="datepicker" placeholder="Date de départ" ngModel required>
                    </div>
                    <div class="col s12 m6">
                      <input autocomplete="off" name="date_arrive" style="color: #948f8f;" type="text" id="datepicker2" class="datepicker" placeholder="Date d'arrivée" ngModel required>
                    </div>
                  </div>
                </div>
                <div class="col s12">
                  <div class="row" required>
                    <p class=" col s12 m6 center" >
                      <label >
                        <input class="with-gap blue" value="aller-simple" name="type_de_voyage" style="color: white;" type="radio" checked ngModel>
                        <span style="color: white; font-weight:lighter; font-size:small">Aller-Simple</span>
                      </label>
                    </p>
                    <p class=" col s12 m6 center">
                      <label >
                        <input class="with-gap" value="aller-retour" name="type_de_voyage" style="color: white;" type="radio" ngModel>
                        <span style="color: white; font-weight:lighter; font-size:small">Aller-Retour</span>
                      </label>
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="row">
                    <button class="btnn col s12 center" type="submit">Reserver
                      <i class="material-icons right">send</i>
                    </button>
                  </div>
                  </div>


              </form>

          </div>
          <!--End Formulaire-->
        </div>
      </div>
      </div>
    </div>

    <!-- END MAIN SLIDER -->
    <!-- SERVICE -->
    <div class="section service">
      <div class="container">
        <div class="row">
          <div class="col s12">
            <div class="section-title">
              <span class="theme-secondary-color">Nos</span> Services
            </div>
          </div>
        </div>
        <div class="row icon-service">

          <div class="col s4 m4 l2">
            <div class="content">
              <div class="in-content">
                <div class="in-in-content">
                  <i class="fa fa-ticket" aria-hidden="true" style="color: #fe0000;"></i>
                  <h5>Vos trajets à<br>l'aeroport</h5>
                </div>
              </div>
            </div>
          </div>
           <div class="col s4 m4 l2">
          </div>
          <div class="col s4 m4 l2">
            <div class="content">
              <div class="in-content">
                <div class="in-in-content">
                  <i class="fa fa-map-o" aria-hidden="true" style="color: #43ba0f;"></i>
                  <h5>Déménagement<br></h5>
                </div>
              </div>
            </div>
          </div>
           <div class="col s4 m4 l2">
          </div>
          <div class="col s4 m4 l2">
          </div>
<div class="col s4 m4 l2">
          </div>
         <div class="col s4 m4 l2">
          </div>
        <div class="col s4 m4 l2">
            <div class="content">
              <div class="in-content">
                <div class="in-in-content">
                  <i class="fa fa-car" aria-hidden="true" style="color: #3f22d1;"></i>
                  <h5>Transports Bagages</h5>
                </div>
              </div>
            </div>
        </div>
          <div class="col s4 m4 l2">
          </div>



      </div>
    </div>
    <div class="section promo">
      <div id="promo-item" class="owl-carousel center">
        <div class="item theme-gradation">
          <div class="promo-caption">
            <h2>See Our Special<br>Promo Offers</h2>
            <a href="single-page.html"><div class="btn transparent">Get Started</div></a>
          </div>
          <div class="background-promo" style="background-image: url(../assets/img/1260x317.jpg);">
          </div>
        </div>
        <div class="item theme-gradation">
          <div class="promo-caption">
            <h2>Best of Destinations<br>Worldwide</h2>
            <a href="single-page.html"><div class="btn transparent">Get Started</div></a>
          </div>
          <div class="background-promo" style="background-image: url(../assets/img/1260x317.jpg);">
          </div>
        </div>
      </div>
    </div>
    <!-- END SERVICE -->
    <!-- NEWS -->
    <div class="section news">
      <div class="container">
        <div class="row row-title">
          <div class="col s12">
            <div class="section-title">
              <span class="theme-secondary-color"></span> News
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 m6">
            <div class="news-content">
              <img src="../../assets/img/voiture.png" alt="image-news">
              <div class="news-detail">
                <h5 class="news-title"><a href="">Notre flotte s'agrandit</a></h5>
                <div class="date text-blue">
                  <span><i class="fa fa-calendar"></i> 1 Novembre 2020</span>
                </div>
                <p>
                  Nous élargissons notre gamme de véhicules utilitaires parfaite pour vos déménagements!
                </p>
              </div>
            </div>
          </div>
          <div class="col s12 m6">
            <div class="news-content">
              <img src="../../assets/img/newapp.png" alt="image-news">
              <div class="news-detail">
                <h5 class="news-title"><a href="">Le site de reservation de CAD est desormais dispo!</a></h5>
                <div class="date">
                  <span><i class="fa fa-calendar"></i> 15 Mars 2021</span>
                </div>
                <p>
                Notre app est desormais disponible. Vous pouvez accéder en ligne à nos service et reserver rapidementvos trajets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END NEWS -->

    <!-- TESTIMONIAL  -->
    <div class="section testimonial">
      <div class="testimonial-wrap">
        <div class="container">
          <div class="row">
            <div class="col s12">
              <div class="section-title">NOS CONTACTS</div>
            </div>
          </div>
        </div>
      </div>
    </div>

<!-- CONTENT -->
<div id="page-content">
  <div class="register-form">
    <div class="container">
      <div class="row">
        <div class="col s12">
          <div class="section-title">
             Nous sommes disponibles tous les jours <span class="theme-secondary-color">24/24</span>
          </div>
        </div>
  </div>
<!-- <div class="row">
<form class="col s12">
  <div class="row">
    <div class="input-field col s12 m6 l4 offset-m3 offset-l4">
              <input id="name" type="text" class="validate">
              <label for="name">Nom</label>
    </div>
  </div>
  <div class="row">
    <div class="input-field col s12 m6 l4 offset-m3 offset-l4">
              <input id="email" type="email" class="validate">
              <label for="email">Email</label>
    </div>
  </div>
  <div class="row">
  <div class="input-field col s12 m6 l4 offset-m3 offset-l4">
              <input id="phone" type="tel" class="validate">
              <label for="phone">Téléphone</label>
  </div>
  </div>
   <div class="row">
   <div class="input-field col s12 m6 l4 offset-m3 offset-l4">
              <textarea id="message" class="materialize-textarea"></textarea>
              <label for="message">Votre message</label>
   </div>
   </div>
   <div class="row">
   <div class="input-field col s12 m6 l4 offset-m3 offset-l4 center">
              <input class="waves-effect waves-light btn" value="Envoyer" type="submit"></div>
    </div>
  </form>

</div> -->
<div class="row">
  <div class="col s12 m3 "></div>
  <div class="col s12 m6 ">
    <div class="card blue darken-4">
      <div class="card-content white-text center">
        <span class="card-title">Villa No: 1170</span>
        <p style="font-size: 1.4em;margin: 1rem 0">Quartier Cheikh Ibra Est Diourbel</p>
        <p  style="font-size: 1.4em;">Email : <a href="mailto:contact@cadtransports.com" class="white-text">cadtransport.info@gmail.com</a></p>
      </div>
    </div>
  </div>
  <div class="col s12 m3"></div>
</div>
</div>
</div>
<div class="col s12 center" style="margin-top: 15px;">
    <span>Ou Contactez nous par whatsapp</span>
    <a href="https://wa.me/776330992?text=Bonjour%20,%20J'aimerai%20savoir%20des%20infos%20sur%20vos%20services%20!" target="_blank">
      <div class="circle-sosmed">
        <i class="fa fa-whatsapp green-text" aria-hidden="true"></i>
      </div>
    </a>
</div>

<!-- END CONTENT -->



