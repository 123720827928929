import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Tarif } from '../models/tarif.models';
import * as firebase from 'firebase';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthenticationService } from './authentication.service';


@Injectable({
 providedIn: 'root'
})


export class Tarifservice {
  private tarifs: any[] = [];

constructor(private db: AngularFireDatabase){}
  getTarifsFromServer() {
    return new Promise<any>((resolve, reject)=> {
      this.db.list('tarif').valueChanges()
              .subscribe(tarifs => {
              resolve(tarifs);
      })
    })

  }

  getSingleTarif(nb_km: number) {

    return new Promise<any>((resolve, reject) => {
      this.getTarifsFromServer()
        .then(tarifs => {
        this.tarifs = tarifs;
        this.tarifs.forEach(el => {
           if (nb_km>=el.min && nb_km<=el.max) {
          resolve(el);
console.log(el.value);
        }
        })
      },(error) =>{
          console.log(error)

      })
    })
  }



}

