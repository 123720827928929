<!-- CONTENT -->
<div id="page-content">
    <div class="register-form">
      <div class="container">
        <div class="row">
          <div class="col s12">
            <div class="section-title">S'inscrire</div>
          </div>
        </div>
        <div class="row">
          <p class="col s12 offset-m3 offset-l4" *ngIf=" errorMessage" style="color: rgb(255, 123, 0);">{{ errorMessage }}</p>
        </div>
        
        <div class="row">
          <form class="col s12" (ngSubmit)="onSubmit(f)" #f="ngForm">
            <div class="row">
              <div class="input-field col s12 m6 l4 offset-m3 offset-l4">
                <input name="name" id="name" type="text" class="validate" ngModel required>
                <label for="name">Votre nom et prenom</label>
              </div>
            </div>
            <div class="row">
              <div class="input-field col s12 m6 l4 offset-m3 offset-l4">
                <input name="tel" id="tel" type="tel" class="validate" ngModel required>
                <label for="tel">Telephone</label>
              </div>
            </div>
            <div class="row">
              <div class="input-field col s12 m6 l4 offset-m3 offset-l4">
                <input name="email" id="email" type="email" class="validate" ngModel required>
                <label for="email">Email</label>
              </div>
            </div>
            <div class="row">
              <div class="input-field col s12 m6 l4 offset-m3 offset-l4">
                <input name="password" id="password" type="password" class="validate" ngModel required>
                <label for="password">Entrer un mot de passe</label>
              </div>
            </div>
            <div class="row">
              <div class="input-field col s12 m6 l4 offset-m3 offset-l4">
                <input name="repassword" id="repassword" type="password" class="validate" ngModel required>
                <label for="repassword">Confirmer le mot de passe</label>
              </div>
            </div>
            <div class="row row-forgot">
              <div class="input-field col s12 m6 l4 offset-m3 offset-l4 center">
                <a class="forgotr" routerLink="/login">Déjà inscrit? Connectez vous ici</a>
              </div>
            </div>
            <div class="row">
              <div class="input-field col s12 m6 l4 offset-m3 offset-l4 center">
                <input class="waves-effect waves-light btn" value="Enregistrer" type="submit" >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- END CONTENT -->