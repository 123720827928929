import { Commandes } from './Commandes.model';

export class Client {
    _userId: number = 0;
    nom: string = "";
    email: string = "";
    tel: number = 0;
    password: string = "";
    commandes: Commandes[] = [new Commandes()];

}