import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Client } from '../models/Client.models';
import {Commandes} from '../models/Commandes.model';
import { AuthenticationService } from '../services/authentication.service';
import { ClientService } from '../services/client.service';
import * as CryptoJs from 'crypto-js';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  errorMessage: string = '';
  commandes: Commandes[] = [];

  constructor(public title: Title,
              private authService: AuthenticationService,
              private clientService: ClientService,
              private router: Router) {
    this.title.setTitle(" Cad - Transport - Register ");
   }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm) {
    const name = form.value['name'];
    const email = form.value['email'];
    const tel = form.value['tel']
    const password = form.value['password'];
    const repassword = form.value['repassword'];
    if (password === repassword) {
      const newClient = new Client();
      newClient._userId = Date.now();
      newClient.nom = name;
      newClient.email = email;
      newClient.tel = tel;
      newClient.password = this.setCrypt('123456$#@$^@1ERF', password);

      try {
        this.clientService.createNewClient(newClient);
        this.authService.addNewUser(email, password).then(
          () => {
            this.authService.login(email, password).then(
              () => {
                this.router.navigate([""])
              },(error) => {
                this.errorMessage = error;
              }
            )
          },(err) => {
            this.errorMessage = err;
          }
        )
      }catch(error) {
        this.errorMessage = error;
      }
    } else {
      this.errorMessage = "La confirmation de mot de passe est incorrecte !"
    } 
  }

  //encrypt password
  setCrypt(keys:any, value:any) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }
}
