import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {CommonModule, HashLocationStrategy, LocationStrategy} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ServicePageComponent } from './service-page/service-page.component';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { ClientService } from './services/client.service';
import { Tarifservice } from './services/tarif.service';
import { vehiculeServices } from './services/vehicule.service';
import { AuthenticationService } from './services/authentication.service';
import { ProfilComponent } from './profil/profil.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { from } from 'rxjs';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import {BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { SendEmailService } from './services/send-email.service';


var firebaseconfig = {
  apiKey: "AIzaSyAMHwHwlf-lD-LUOGnfui8I99pHb4NAIqo",
  authDomain: "cadapp-ba427.firebaseapp.com",
  projectId: "cadapp-ba427",
  storageBucket: "cadapp-ba427.appspot.com",
  messagingSenderId: "629018247314",
  appId: "1:629018247314:web:13b4602084ae5e31c6e64b"
};

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    LoginComponent,
    RegisterComponent,
    ServicePageComponent,
    ProfilComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(firebaseconfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    
  ],
  providers: [
    ClientService,
    AuthenticationService,
    Tarifservice,
    vehiculeServices,
    ToastrService,
    SendEmailService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
