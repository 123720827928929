import * as moment from "moment";

export class Commandes {
    _cmID: number = 0;
    value: number=0;
    _userId: number = 0;
    _vhId : number = 0;
    created_at: Date = new Date();
    date_depart: any= "";
    date_retour: any="";
    lieu_depart: string = "";
    lieu_arrive: string = "";
    active: boolean = true;
    type : string = "aller-simple" || "aller-retour";

}
