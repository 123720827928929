<!-- HEADER -->
<header id="header">
    <div class="nav-wrapper container">
     <div class="header-logo " style="margin-top: 1.2rem;">
       <!-- <i class="fa fa-user-secret" aria-hidden="true"></i> Travel -->
     <a href="" class="nav-logo"><img src="../assets/img/logo.jpg" alt=""></a>
      </div>
      <div  [hidden]="!islogin" class="header-menu-button">
        <a type="button" data-target="slide-out" data-activates="slide-out" class="button-collapse sidenav-trigger">
        <div class="cst-btn-menu">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </div>
        </a>
      </div>
    </div>
  </header>
  <!-- END HEADER -->
  <!-- SIDE NAV -->
  <nav >
    <ul id="slide-out" class="side-nav collapsible collapsible-accordion sidenav">
      <li class="profil">
        <img src="../assets/img/150x150.jpg" alt="profile">
        <h2 *ngIf="client">{{client.nom}}</h2>

      </li>
      <li>
        <a routerLink="" class="sidenav-close"><i class="fa fa-home" aria-hidden="true"></i>Home</a>
      </li>
      <li>
        <ul class="collapsible">
          <li>
            <div class="collapsible-header">
              <i class="fa fa-ticket" aria-hidden="true"></i>Mes commandes <span><i class="fa fa-caret-down"></i></span>
            </div>
            <div class="collapsible-body">
              <ul>
                <li>
                  <a class="waves-effect waves-blue sidenav-close" (click)="onClickScroll('encours')"><i class="fa fa-angle-right" aria-hidden="true"></i>En cours</a>
                </li>
                <li>
                  <a class="waves-effect waves-blue sidenav-close" (click)="onClickScroll('termined')"><i class="fa fa-angle-right" aria-hidden="true"></i>Terminées</a>
                </li>
                <li>
                  <a class="waves-effect waves-blue sidenav-close" (click)="onClickScroll('factures')"><i class="fa fa-angle-right" aria-hidden="true"></i>Mes factures</a>
                </li>
                <li>
                  <a class="waves-effect waves-blue sidenav-close" (click)="onClickScroll('trajets')"><i class="fa fa-angle-right" aria-hidden="true"></i>Mes trajets</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
      <li>
        <a class="sidenav-close" routerLink="services"><i class="fa fa-map" aria-hidden="true"></i>Services</a>
      </li>
      <li>
        <a class="sidenav-close" routerLink=""><i class="fa fa-pencil-square" aria-hidden="true"></i>Blog</a>
      </li>
      <li>
        <a class="sidenav-close" routerLink=""><i class="fa fa-envelope" aria-hidden="true"></i>Contact</a>
      </li>
      <li>
        <a class="sidenav-close" routerLink="/profil"><i class="fa fa-cogs" aria-hidden="true"></i>Paramêtres</a>
      </li>
    </ul>
  </nav>
  <!-- END SIDE NAV -->
