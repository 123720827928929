import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';
import { Client } from '../models/Client.models';
import { AngularFireDatabase } from '@angular/fire/database';
import { ThrowStmt } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  public clients: any[] = [];
  public clientSub = new Subject<Client[]>();

  constructor(private db: AngularFireDatabase,
              ) { 
    this.getClientsFromServer().then(
      clients => {
        this.clients = clients
      }
    )
  }

  emitClient() {
    this.clientSub.next(this.clients);
  } 

  saveClient() {
    this.db.object('clients').update(this.clients)
  }

  // add new client in database
  createNewClient(newclient: Client) {
    this.clients.push(newclient);
    this.saveClient();
    this.emitClient();
  }

  // get all clients from dabase
  getClientsFromServer() {
    return new Promise<any>((resolve, reject) => {
      this.db.list('clients').valueChanges()
              .subscribe(clients => {
                resolve(clients)
      })
    })
    
  }

  // get single client in database
  getSingleClient(email: string) {
    return new Promise<any>((resolve, reject) => {
      this.getClientsFromServer()
        .then(clients => {
          this.clients = clients;
          this.clients.forEach(el => {
            if (el.email === email) {
              var id = this.clients.indexOf(el)
              resolve([id, el])
            }
          })
        },(error) =>{
          console.log(error)
      })
    })
  }

  //update a client
  updateClient(id: number, value: any): Promise<void> {
    return this.db.object('/clients/'+ id).update(value);
  }
  

}
