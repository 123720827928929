import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  errorMessage : string = '';

  constructor(public title: Title,
              private authService: AuthenticationService,
              private router: Router) {
    this.title.setTitle(" Cad - Transport - Login");
   }

  ngOnInit(): void {
  }

  onLogin(form: NgForm) {
    const email = form.value['email'];
    const password = form.value['password'];
    try{
      this.authService.login(email, password).then(
        () => {
          console.log("Connected !");
          this.router.navigate(["/profil"]);
        },
        (error) => {
          this.errorMessage = error.message;
          console.log(error);
        }
      )
    }catch(error){
      console.log(error);
    }

  }

}
